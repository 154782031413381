export default [
    {
        title: 'Inicio',
        route: 'home',
        icon: 'HomeIcon',
    },
    {
        title: 'Clientes',
        icon: 'UsersIcon',
        children: [
            {
                title: 'Lista',
                route: 'customers',
            }
        ]
    },
]
