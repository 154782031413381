<template>
	<div class="navbar-header d-xl-block d-none">
		<ul class="nav navbar-nav">
			<li class="nav-item">
				<b-link
					class="navbar-brand"
					to="/"
				>
					<span class="brand-logo">
						<b-img
							:src="(skin == 'dark' ? appLogoImage : appLogoImageDark)"
							alt="logo"
						/>
					</span>
					<h2 class="brand-text mb-0">
						{{ appName }}
					</h2>
				</b-link>
			</li>
		</ul>
	</div>
</template>

<script>

	import useAppConfig from '@core/app-config/useAppConfig';
	import { BLink, BImg } from 'bootstrap-vue';
	import { $themeConfig } from '@themeConfig';

	export default {
		components: {
			BLink,
			BImg
		},
		data() {
            return {
                logoImage: require('@/assets/images/pages/not-authorized.svg'),
            }
        },
		setup() {
			// App Name
			const { appName, appLogoImage, appLogoImageDark } = $themeConfig.app

			const { skin } = useAppConfig()

			return {
				skin,

				appName,
				appLogoImage,
				appLogoImageDark
			}
		}
	}

</script>
